<template>
    <input ref="elRef" v-model="model" type="checkbox">
</template>

<script lang="ts">
import type { DataColumn } from 'o365-datagrid';

export interface IProps {
    column: DataColumn,
};
</script>

<script setup lang="ts">
import { ref } from 'vue';

defineProps<IProps>();

const model = defineModel<boolean>();
const elRef = ref<HTMLElement>(null);

function activateEditor() {
    elRef.value?.focus();
}

defineExpose({ elRef, activateEditor });
</script>